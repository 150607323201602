import React from "react";
import user01 from '../image/user01.png'
import user02 from '../image/user02.png'
const Header = ({ trackBar }) => {
  const users = [user01,user02,user01,user02];
  return (
    <div>
      <div className="headerBg  ">
        <div className="bg-slate-300 bg-opacity-75 flex flex-col items-center ">
          <div className="container pt-36 p-5 font-sans col-span-1 ">
            <h1 className=" font-bold text-6xl sm:text-8xl text-center text-slate-800	">
             <b className=" text-blue-600"> BHARAT</b> Parcel<br /> Services Pvt Ltd.
            </h1>
            <p className="text-center p-4 font-semibold text-sm sm:text-lg">
            Track with ease, embrace the journey <br />Bharat Parcel Services – Your parcel's whereabouts at your fingertips, delivering peace of mind
            </p>
          </div>
          <div className="relative w-72 sm:w-1/2 lg:w-1/2 text-white drop-shadow-lg items-center justify-between rounded-full bg-blue-400 font-sans">
            <input
              className="w-full p-4 px-6 sm:text-xl text-left lg:text-center placeholder:text-stone-100 bg-blue-400 font-bold border-none rounded-full "
              placeholder="Enter your order ID"
              type="text"
              name="search"
            />
            <button
              className=" absolute right-0 sm:text-xl rounded-full shadow-lg p-4 px-6 sm:px-12 font-sbold rounded-m bg-blue-800 "
              type="submit" onClick={trackBar}
            >
              Track
            </button>
          </div>
          <div className=" container-fluid my-10 md:my-20 flex justify-center items-center gap-5">
          <dd x-for="user in project.users" className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-1.5">
          {users.map((user, index)=>(
              <img src={user} key={index} alt="user.name" className="w-14 h-14 rounded-full bg-slate-100 ring-2 ring-white" loading="lazy"/>
          ))}
            </dd>
            <div className="text-center font-bold font-sans">
              <h1 className=" text-4xl">95K+</h1>
              <p>Joined in already</p>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
