import React from "react";
import Card from "./Card";
import Cart from "../image/Cart.svg";
import Payment from "../image/Payment.svg";
import Office from "../image/Office.svg";
import deliverybox from "../image/deliverybox.svg";

const TrackOrder = () => {
  return (
    <div>
      <div className=" bg-blue-200 bg-bgElement bg-no-repeat bg-right  md:flex justify-around md:p-10">
        <div className="container p-4 ">
          <div className=" container text-center p-7 lg:my-5 ">
            <h1 className="text-4xl sm:text-6xl font-bold font-san ">
              How to Track Orders in BPS
            </h1>
            <p className="py-4 font-semibold">
              Experience effortless tracking with our intuitive system. Uncover
              real-time insights into your shipment's journey. <br /> Elevate
              your order management with a seamless process, ensuring
              transparency and peace of mind.
            </p>
          </div>
          <div className="p-10 grid sm:grid-cols-2 lg:grid-cols-4 gap-10 items-stretch">
            <Card
              src={Cart}
              title="Enter your Order ID"
              desc="Enter your Order ID for a seamless tracking experience - your package, your journey, our commitment."
            />
            <Card
              src={Payment}
              title="Track your Order"
              desc="Seamless updates for confident deliveries, ensuring transparency in every step of your package's journey."
            />
            <Card
              src={Office}
              title="Please wait, your order will arrive soon"
              desc="Your delivery is on its way. Anticipate the moment with confidence. Arrival is imminent."
            />
            <Card
              src={deliverybox}
              title="Your order has successfully arrived."
              desc="Your delivery, a triumph of efficiency. Celebrate the seamless arrival of your order with confidence."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;
