import React from "react";
import TitleHead from "../TitleHead";
import QuestonsArea from "../../component/QuestionArea";
import Contact from "../../component/Contact";

const OurVision = () => {
  return (
    <>
      <TitleHead title=" Our Vison" />
      <div className="bg-blue-100 bg-bgElement bg-no-repeat p-7 lg:px-20">
        <div className="bg-gradient-to-b from-blue-300 rounded-3xl lg:p-18 p-8">
          <p className="text-xl text-center">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda,
            iure ratione. Rem dolore nisi facilis? Quisquam exercitationem
            dolore perferendis aliquid eos ab beatae modi consequatur quibusdam
            laborum veritatis, nobis quae error, nostrum ullam commodi nihil
            repudiandae labore earum, sit nisi aut eveniet accusantium
            excepturi. Similique eum tempora molestiae labore ea?
          </p>
        </div>
      </div>
      <QuestonsArea />
      <Contact />
    </>
  );
};

export default OurVision;
