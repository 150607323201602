import mail from '../image/mail.svg';
import location from '../image/location.svg';
import phone from '../image/phone.svg';
import yt from '../image/yt.svg';
import fb from '../image/fb.svg';
import ig from '../image/ig.svg';
import tw from '../image/tw.svg';

const Footer =()=>{
    return(
        <>
        <div className="bg-blue-800 bg-bgElement bg-no-repeat bg-right lg:flex items-center justify-around">
            <div className="container p-6  grid grid-flow-row-grid grid-flow-row-dense grid-cols-2 lg:grid-cols-9 gap-5 lg:my-10 ">
                    <div className="col-span-2 p-2 sm:p-5  ">
                        <h1 className="text-2xl text-white font-bold">Logo</h1>
                        <p className="p-1 text-white text-sm sm:text-base">Bharat Parcel Services – Your parcel's whereabouts at your fingertips, delivering peace of mind</p>
                    </div>
                    <div className="col-span-2 p-2 sm:p-5 ">
                        <h1 className="text-xl text-white font-bold">Quick Links</h1>
                        <ul className="text-white">
                            <a href="/" ><li className="font-semibold p-1 text-white text-sm sm:text-base">About Us</li></a>
                            <a href="/" ><li className="font-semibold p-1 text-white text-sm sm:text-base">Service</li></a>
                            <a href="/" ><li className="font-semibold p-1 text-white text-sm sm:text-base">How to Track</li></a>
                            <a href="/" ><li className="font-semibold p-1 text-white text-sm sm:text-base">Testimonial</li></a>
                        </ul>
                    </div>
                    <div className="col-span-2 p-2 sm:p-5 ">
                        <h1 className="text-xl text-white font-bold">Contact Us</h1>
                        <div className="flex justify-start">
                            <img src={mail} alt="logo"  className='p-1'/>
                            <p className='p-1 text-white text-sm sm:text-base'>caarun@rediffmail.com</p>
                        </div>
                        <div className="flex justify-start">
                            <img src={location} alt="logo"  className='p-1'/>
                            <p className='p-1 text-white text-sm sm:text-base'>332, KUCHA GHASI RAM, FATEH PURI, CHANDNI CHOWK DELHI DL 110006</p>
                        </div>
                        <div className="flex justify-start">
                            <img src={phone} alt="logo"  className='p-1'/>
                            <p className='p-1 text-white text-sm sm:text-base'>+91 7017866149</p>
                        </div>
                    </div>
                    <div className="col-span-3 p-2 sm:p-5">
                        <h1 className="text-xl text-white font-bold">Newsletter</h1>
                        <div className="flex justify-start my-6">
                            <input type="text" placeholder='Enter your email ' className='rounded-l-xl w-1/2 sm:w-auto'/>
                            <button type='submit' className='text-white text-center bg-green-500 p-4 rounded-r-xl'>Subscribe</button>
                        </div>
                        <div className='grid grid-cols-4 p-4'>
                                <a href="/"><img src={yt} alt="logo" className='p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg ' /></a>
                                <a href="/"><img src={tw} alt="logo" className='p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg ' /></a>
                                <a href="/"><img src={ig} alt="logo" className='p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg ' /></a>
                                <a href="/"><img src={fb} alt="logo" className='p-1 border-2 hover:border-green-600 hover:bg-green-500 rounded-lg ' /></a>
                            </div>
                    </div>
                
            </div>
        </div>
        </>
    );
}

export default Footer;