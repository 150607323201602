import axios from "axios";
import secureLocalStorage from "react-secure-storage";
//-------BPS API to Trail------------------------- 
const API_URL = "https://api.bharatparcel.org";  
// const API_URL = "https://admin.bharatparcel.org";  

export const getAxiosWithToken = async (config) => {
  // Data
  let data = undefined;
  if (config.data) {
    data = config.data;
  }
  // Data
  let body = undefined;
  if (config.body) {
    body = config.body;
  }

  // Method
  let method = "POST";
  if (config.method) {
    method = config.method;
  }

  let reqType = "application/json";
  if (config.reqType &&  config.reqType === "formData") {
    reqType= "application/form-data"
  }

  // URL
  let URL = API_URL;
  if (config.url) {
    URL = config.url;
  }

  let response; // Declare the response variable

  // try {
  response = await axios({
    method: method,
    url: `${API_URL}/${URL}`,
    headers: {
      "Content-Type": reqType,
      Authorization: "Bearer " + secureLocalStorage.getItem("access"),
    },
    data: data,
    body: body,
  });


  // } catch (error) {
  //   alertResponseMsgError(error.response?.data?.msg || "Something went wrong...!");
  // }

  return response; // Return the response
};

export const getAxiosWithOutToken = async (config) => {
  // Data
  let data = undefined;
  if (config.data) {
    data = config.data;
  }

  // Method
  let method = "POST";
  if (config.method) {
    method = config.method;
  }

  // URL
  let URL = API_URL;
  if (config.url) {
    URL = config.url;
  }

  let response; // Declare the response variable

  // try {
  response = await axios({
    method: method,
    url: `${API_URL}/${URL}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });

  // response = response.data; // Assign the value to the response variable
  // } catch (error) {
  //   alertResponseMsgError(error.response?.data?.msg || "Something went wrong...!");
  // }

  return response; // Return the response
};
