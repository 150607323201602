import React from "react";
import Vector01 from "../image/Vector01.svg";
import volume from "../image/volume.svg";
import distance from "../image/distance.svg";
import time from "../image/time.svg";
import Mapper from "./Map";
import Tracker from "../component/Tracker"



const Mappage = () => {
  return (
    <div>
      <div className="flex items-center justify-center bg-blue-100 bg-bgElement bg-no-repeat p-7 lg:p-20 ">
        <div className="grid grid-rows-1 grid-flow-row gap-10 lg:w-2/3 ">
          <div className="row-span-1 bg-blue-800 rounded-2xl p-4">
            <h1 className="text-white text-xl sm:text-2xl font-bold font-san py-2">#127777489-DL-NY</h1>
            <button type="submit" className="font-semibold w-full p-3 bg-white rounded-2xl ">Out for Delivery</button>
            <div>
                <Tracker/>
            </div>
            </div>
          <div className="row-span-2 p-2 rounded-2xl sm:w-96">
            <Mapper/>
          </div>
          <div className="row-span-1 bg-blue-800 rounded-2xl">
          <div className=" p-4 ">
          <h1 className="text-white text-xl font-semibold font-san">
          Delivering at:
          </h1>
          <p className="text-white py-4 ">
          Nehru Ganj nayi basti, Darshan Bhawan, near MAX Hospital, 
         back of DPBS College, Anupshahr - 203390, Uttar Pradesh
          </p>
        </div>
          </div>
          <div className="sm:col-span-2">
            <div className="p-5 grid sm:grid-cols-4 grid-cols-2 gap-3 sm:gap-10">
              <div className=" card p-5 font-sans">
                <img src={Vector01} alt="img" className="p-1" />
                <div className="flex items-end justify-center gap-2 ">
                  <h1 className=" text-3xl lg:text-6xl text-blue-700 font-bold">101</h1>
                  <h1 className=" text-xl text-blue-700 font-bold">kg</h1>
                </div>
                <p className="sm:text-lg p-2 text-blue-700">Payload</p>
              </div>
              <div className=" card p-5 font-sans">
                <img src={volume} alt="img" className="p-1" />
                <div className="flex items-end justify-center gap-2 ">
                  <h1 className=" text-3xl lg:text-6xl text-blue-700 font-bold">123</h1>
                  <h1 className=" text-xl text-blue-700 font-bold">In</h1>
                </div>
                <p className="sm:text-lg p-2 text-blue-700">Volume</p>
              </div>
              <div className=" card p-5 font-sans">
                <img src={distance} alt="img" className="p-1" />
                <div className="flex items-end justify-center gap-2 ">
                  <h1 className=" text-3xl lg:text-6xl text-blue-700 font-bold">50</h1>
                  <h1 className=" text-xl text-blue-700 font-bold">Km</h1>
                </div>
                <p className="sm:text-lg p-2 text-blue-700">Distance</p>
              </div>
              <div className=" card p-5 font-sans">
                <img src={time} alt="img" className="p-1" />
                <div className="flex items-end justify-center gap-2 ">
                  <h1 className=" text-3xl lg:text-6xl text-blue-700 font-bold">90</h1>
                  <h1 className=" text-xl text-blue-700 font-bold">Mins</h1>
                </div>
                <p className="sm:text-lg p-2 text-blue-700">Estimated Time</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mappage;
