import React from "react";
import TitleHead from "./TitleHead";
import LogisticServices from "../component/LogisticServices";
import BpsDelivery from "../component/BpsDelivery";
import ParcelDelivery from "../component/ParcelDelivery";
import FeedBack from "../component/FeedBack";
import QuestonsArea from "../component/QuestionArea";

const OurService = () => {
  return (
    <>
    {/* title pass through the Props */}
      <TitleHead title="Our Services" /> 
      
      <LogisticServices/>
      <BpsDelivery/>
      <ParcelDelivery/>
      <FeedBack/>
      <QuestonsArea/>
    </>
  );
};

export default OurService;
