import React from "react";
import Qicon from "../image/Qicon.svg";

const QuestonsArea = () => {
  return (
    <div>
      <div className=" bg-blue-100 bg-bgElement bg-no-repeat bg-left-bottom md:flex items-center justify-around">
        <div className="container lg:p-10 sm:flex ">
          <div className=" container p-10 lg:my-24 text-center">
            <h1 className="text-4xl sm:text-6xl font-bold font-san ">
              Have Any Questions?
            </h1>
            <p className="p-5 py-5 font-semibold  ">
            Inquiries welcomed. <br /> Discover excellence in delivery with our <br /> expert customer support.
            </p>
          </div>
          <div className=" container p-6 sm:p-12 ">
            <div className=" card grid justify-items-left grid-cols-1 p-8 gap-6">
              <div className="flex items-center ">
                <img src={Qicon} alt="."  />
                <h1 className="lg:text-xl text-lg font-bold p-1">How can i become a partner with BPS?</h1>
              </div>
              <div className="flex items-center ">
                <img src={Qicon} alt="." />
                <h1 className="lg:text-xl text-lg font-bold p-1">How do i schedule a delivery?</h1>
              </div>
              <div className="flex items-center ">
                <img src={Qicon} alt="." />
                <h1 className="lg:text-xl text-lg font-bold p-1">Do you operate 24/7?</h1>
              </div>
              <div className="flex items-center ">
                <img src={Qicon} alt="." />
                <h1 className="lg:text-xl text-lg font-bold p-1">Can i track my orders?</h1>
              </div>
              
  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestonsArea;
