import React from "react";
import Vector1 from "../image/Vector1.svg";
import Vector2 from "../image/Vector2.svg";
import Vector3 from "../image/Vector3.svg";
import Vector4 from "../image/Vector4.svg";


const BrandPartner = () => {
  return (
    <div>
      <div className=" bg-blue-800 bg-bgElement bg-no-repeat lg:flex items-center justify-around p-4">
        <div className=" p-4 grid md:grid-cols-3 justify-items-center items-center ">
          <div className=" col-span-1 p-10 ">
            <h1 className="text-4xl sm:text-6xl font-bold font-sans text-white ">
              Trusted By Top Companies
            </h1>
            <p className="sm:py-9 py-4 text-white font-semibold">
              Chosen by leading enterprises <br /> Simplifying tracking for a seamless delivery experience
            </p>
          </div>
          <div className=" col-span-2  ">
            <dd className=" my-4 md:pl-12  lg:flex  gap-9">
              <img src={Vector1} alt="dfsdf" loading="lazy"className="mb-3"/>
              <img src={Vector3} alt="dfsdf" loading="lazy" className=""/>
            </dd>
            <dd className="my-12  lg:flex  gap-9 ">
              <img src={Vector2} alt="dfsdf" loading="lazy" className="mb-3" />
              <img src={Vector3} alt="dfsdf" loading="lazy" />
            </dd>
            <dd className="my-4 md:pl-12 lg:flex  gap-9">
              <img src={Vector3} alt="dfsdf" loading="lazy" className="mb-3" />
              <img src={Vector4} alt="dfsdf" loading="lazy"/>
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPartner;
