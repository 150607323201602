import React from 'react'
import Card from './Card'
import Truck from '../image/Truck.svg'
import Api from '../image/Api.svg'
import Fast from '../image/Fast.svg'
import Service from '../image/Service.svg'
const ServiceBar = () => {
  return (
    <div>
        <div className=" bg-blue-200 bg-bgElement bg-no-repeat bg-right-bottom md:flex items-center justify-around">
            <div className="container p-4 lg:flex justify-around">
            <div className=" container p-10 lg:my-24 ">
                <h1 className='text-4xl sm:text-6xl font-bold font-san '>Why choose Bharat Parcel Services?</h1>
                <p className='py-5 font-semibold '>Your Prime Choice for Reliable and <br/> Efficient Parcel Delivery Solutions</p>
            </div>
            <div className=" p-10 grid sm:grid-cols-2 gap-10 items-center">
              <div><Card src={Truck} title='Experienced driver' desc="Seasoned drivers, delivering reliability with every parcel." /></div>
              <div><Card src={Api} title="Integration of API" desc="Effortless connectivity, elevate your logistics with seamless integration."/></div>
              <div><Card src={Fast} title="Quick Delivery" desc="Swift dispatch, ensuring your parcels reach with prompt precision."/></div>
              <div><Card src={Service} title="Outstanding service" desc="Excellence in Every Delivery, Your Parcel, Our Priority."/></div>
              
            </div>
            
        </div>
        </div>
    </div>
  )
}

export default ServiceBar
