import React from "react";

const ParcelDelivery = () => {
  return (
    <div>
      <div className=" bg-blue-100 bg-bgElement bg-no-repeat  md:flex justify-around lg:px-20">
        <div className=" p-4 ">
          <div className=" container text-center p-7 lg:my-5 ">
            <h1 className="text-4xl sm:text-6xl font-bold font-san ">
              Stats For Parcel Delivery
            </h1>
            <p className="py-4 font-semibold">
            Experience excellence in logistics. Elevate your delivery journey with our unparalleled service. Unleashing efficiency, our advanced tracking <br /> system ensures transparency and punctuality, setting industry standards for seamless parcel delivery.
            </p>
          </div>
          <div className="text-center p-5 grid sm:grid-cols-4 grid-cols-2 gap-3 sm:gap-10">
          <div className=" card p-7 font-sans">
              <h1 className=" text-3xl lg:text-6xl font-bold">95%+</h1>
              <p className="font-semibold p-2">on-time delivery rate</p>
            </div>
            <div className=" card p-7 font-sans">
              <h1 className=" text-3xl lg:text-6xl font-bold">2600+</h1>
              <p className="font-semibold p-2">deliveries per month</p>
            </div>
            <div className=" card p-7 font-sans">
              <h1 className=" text-3xl lg:text-6xl font-bold">5-Star</h1>
              <p className="font-semibold p-2">Reviews from the clients</p>
            </div>
            <div className=" card p-7 font-sans">
              <h1 className=" text-3xl lg:text-6xl font-bold">2M+</h1>
              <p className="font-semibold p-2">Happy Costumers</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParcelDelivery;
