import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

const steps = [
  {
    label: "Package has left Courier Facility",
    description: "San Francisco, California",
  },
  {
    label: "Package arrived at Local Facility",
    description: "NEW YORK CITY, NEW YORK",
  },
  {
    label: "Out for Delivery",
    description: "NEW YORK CITY, NEW YORK",
  },
  {
    label: "Delivered",
    description: "1567 DOVE STREET, NEW YORK CITY, 9888",
  },
];

export default function Tracker({ step }) {
  const [activeStep, setActiveStep] = React.useState(0);
  React.useEffect(() => {
    // This will be called after the initial render
    setActiveStep(1);
  }, []);

  const currentDate = new Date();

  return (
    <Box sx={{ maxWidth: 400, p: 2 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel variant="p" sx={{ color:"white", background:"white", borderRadius:5, p:1, my:0.5}} >
              {step.label}
            </StepLabel>
            <div className="lg:flex justify-start items-start gap-6 p-1">
              <Typography color="white" variant="subtitle1">
                {step.description}
              </Typography>
              <Typography color="white" variant="subtitle3" style={{ width: '8rem' }}>
                {currentDate.toLocaleString()}
              </Typography>
            </div>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
