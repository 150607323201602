import React from 'react'

const TitleHead = (props) => {
  return (
    <div>
        <div>
      <div className="headerBg  ">
        <div className="bg-slate-100 bg-opacity-75 flex flex-col items-center ">
          <div className="container pt-24 p-5 font-sans col-span-1 ">
            <h1 className=" font-bold  text-3xl sm:text-6xl text-center text-blue-800 p-8">
            {props.title}
            </h1>
          </div>
        
        </div>
      </div>
    </div>
      
    </div>
  )
}

export default TitleHead
