import React from "react";
import { Card2 } from "./Card";
import Delivery from "../image/Delivery.svg";
import Temperature from "../image/Temperature.svg";
import Route from "../image/Route.svg";
import Navigation from "../image/Navigation.svg";

const BpsDelivery = () => {
  return (
    <div>
      <div className=" bg-blue-100 bg-bgElement bg-no-repeat  md:flex justify-around lg:px-20 ">
        <div className=" p-4 ">
          <div className=" container text-center p-7 lg:my-5 ">
            <h1 className="text-4xl sm:text-6xl font-bold font-san ">
              The BPS delivery service
            </h1>
            <p className="py-5 font-semibold ">
            Reliable deliveries redefine expectations. Your package's journey, seamlessly tracked. <br /> BPS Delivery - Where trust meets precision in every delivery moment.
            </p>
          </div>
          <div className=" container p-5 grid sm:grid-cols-2 gap-10">
            <div>
              <Card2
                src={Delivery}
                title="STAT Deliveries"
                desc="STAT Deliveries: Precision in every parcel, your trusted delivery partner."
              />
            </div>
            <div>
              <Card2
                src={Temperature}
                title="Cold Chain Logistics"
                desc="Reliable cold chain logistics, ensuring seamless temperature-controlled supply chains globally."
              />
            </div>
            <div>
              <Card2
                src={Route}
                title="Good and on-time service"
                desc="Punctual deliveries, excellence assured. Your trusted partner for impeccable, timely services."
              />
            </div>
            <div>
              <Card2
                src={Navigation}
                title="Multi-State Solutions"
                desc="Efficient cross-state logistics, your trusted partner for seamless nationwide solutions."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpsDelivery;
