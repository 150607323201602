import React from 'react'
import { ServiceCard } from './Card'
import ship from '../image/ship.svg'
import Icon from '../image/Icon.svg'
import Icon01 from '../image/Icon01.svg'
import Icon02 from '../image/Icon02.svg'
import Icon03 from '../image/Icon03.svg'
import Icon04 from '../image/Icon04.svg'



const LogisticServices = () => {
  return (
    <div>
        <div className="bg-blue-100 bg-bgElement bg-no-repeat p-7 lg:p-20">
        <div className=" text-center sm:mb-16">
          <h1 className="text-4xl font-bold font-san  ">
          Our Logistics Services
          </h1>
        </div>
      <div className='grid grid-cols-1 md:grid-cols-3 justify-end gap-10'>
        <ServiceCard src={ship} title="Sea Transport Services" desc="Efficient maritime solutions, connecting continents with reliable sea transport excellence."/>
        <ServiceCard src={Icon} title="Warehousing Services" desc="Streamlined storage solutions, optimizing logistics for your business success."/>
        <ServiceCard src={Icon01} title="Air Fright Services" desc="Reliable global air freight solutions, ensuring timely delivery with precision."/>
        <ServiceCard src={Icon02} title="Project & Exhibition" desc="Exemplifying excellence: Elevating projects and exhibitions with precision and finesse."/>
        <ServiceCard src={Icon03} title="Local Shipping Services" desc="Local excellence in shipping, connecting communities with reliable delivery solutions."/>
        <ServiceCard src={Icon04} title="Customer Clearance" desc="Navigating clearances, ensuring swift international deliveries with precision and expertise."/>
      </div>
      <div className='flex justify-center p-8'>
      <button type="submit" className="bg-blue-800 rounded-xl p-3 px-7 items-center text-white">More Works</button>
      </div>
      </div>
    </div>
  )
}

export default LogisticServices;
