import React from "react";
import FeedBackCard from "./FeedBackCard";
import user from '../image/user.png';
import user04 from '../image/user04.png';
import user05 from '../image/user05.png'



const FeedBack = () => {
  return (
    <>
      <div className=" bg-blue-100 bg-bgElement bg-no-repeat bg-right   md:flex justify-around ">
        <div className=" object-none object-center p-4 ">
          <div className="  text-center p-7 lg:my-5 ">
            <h1 className="text-4xl sm:text-6xl font-bold font-san ">
              See What Others People Are Saying
            </h1>
            <p className="py-4 ">
            Embraced by industry giants. Client acclaim speaks volumes. Discover unparalleled logistics excellence through seamless tracking. <br /> Join those who trust our delivery solutions—a testament to our commitment to excellence in service.
            </p>
          </div>
          <div className="bg-bgBox bg-no-repeat bg-center sm:bg-contain rounded-2xl  p-5 ">
            <div className=" grid  lg:grid-cols-3 gap-10 xl:p-24">
            <FeedBackCard src={user} userName='Gary Pollich' desig='Future Group Facilitator' desc="''Flawless deliveries every time. Impeccable service, prompt tracking, and reliability make Parcel Services my top choice. Highly recommended!'' "/>
            <FeedBackCard src={user04} userName='Vicki Rohan' desig='Dynamic Usability Specialist' desc="''Effortless tracking, impeccable service. Reliable deliveries every time. Seamless experience, top-notch logistics. Customer-centric excellence, my preferred choice for parcels.'' "/>
            <FeedBackCard src={user05} userName='Marjorie Schinner' desig='Corporate Markets Consultant' desc="''Exceptional service! Seamless tracking, timely deliveries. Impressed by the reliability and efficiency. Highly recommend for stress-free parcel management.'' "/>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default FeedBack;
