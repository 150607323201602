
const FeedBackCard = (props) => {
  return (
    <div>
        <div className='p-5 bg-white rounded-2xl shadow-lg font-sans'>
          <div className="flex items-center">
                <img src={props.src} alt="logo" className=' w-16 rounded-full shadow' />
                <div className="p-4">
                <h1 className='text-xl font-bold '>{props.userName}</h1>
            <p className=''>{props.desig}</p>
            </div>
                </div>
                <div>
            <h1 className=' pt-3 text-2xl font-bold '>{props.title}</h1>
            <p className='p-2'>{props.desc}</p>
            </div>
        </div>
    </div>
  )
}
export default FeedBackCard;