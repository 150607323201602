import React from "react";
import TitleHead from "./TitleHead";
import QuestonsArea from "../component/QuestionArea";
import Contact from "../component/Contact";

const ContactUs = () => {
  return (
    <>
    {/* title pass through the Props */}
      <TitleHead title="Contact Us" /> 
      <Contact/>
      <QuestonsArea/>
    </>
  );
};

export default ContactUs;
