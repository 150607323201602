import React, {useState} from 'react'
import Header from './Header'
import BrandPartner from './BrandPartner'
import ServiceBar from './ServiceBar'
import BpsDelivery from './BpsDelivery'
import TrackOrder from './TrackOrder'
import ParcelDelivery from './ParcelDelivery'
import FeedBack from './FeedBack'
import QuestonsArea from './QuestionArea'
import Contact from './Contact'
import Mappage from './Mappage'

const Dashboard = () => {
  const [isOpen, setIsOpen] =useState();
  const trackBar=()=>{
    setIsOpen(!isOpen);
  }
  return (
    <div>
        <Header trackBar={trackBar}/>
        {isOpen ? <Mappage/>: '' }
        <BrandPartner/>
        <ServiceBar/>
        <BpsDelivery/>
        <TrackOrder/>
        <ParcelDelivery/>
        <FeedBack/>
        <QuestonsArea/>
        <Contact/>
    </div>
  )
}

export default Dashboard;
