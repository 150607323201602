import React from 'react'

const Card = (props) => {
  return (
    <div>
        <div className='p-5 bg-white rounded-2xl shadow-lg font-sans hover:shadow-none hover:-mt-1 sm:h-80'>
          <div>
                <img src={props.src} alt="logo" className='bg-blue-900 p-2 rounded-3xl shadow' />
                </div>
                <div>
            <h1 className=' pt-3 text-2xl font-bold '>{props.title}</h1>
            <p className='p-2'>{props.desc}</p>
            </div>
        </div>
    </div>
  )
}
export default Card;

//this a second card for serviceBar
export const Card2 = (props) => {
  return (
    <div>
        <div className='p-5 bg-white rounded-2xl shadow-lg font-sans flex items-center'>
          <div className=" flex-shrink-0  p-3">
                <img src={props.src} alt="logo" className='bg-blue-900 p-2 rounded-3xl shadow' />
                </div>
                <div>
            <h1 className=' pt-3 text-2xl font-bold '>{props.title}</h1>
            <p className='p-2'>{props.desc}</p>
            </div>
        </div>
    </div>
  )
}

//this a second card for serviceBar
export const ServiceCard = (props) => {
  return (
    <div>
        <div className='p-5 font-sans'>
          <div>
                <img src={props.src} alt="logo" className='p-2' />
                </div>
                <div>
            <h1 className=' py-2 text-2xl lg:text-3xl font-semibold '>{props.title}</h1>
            <p className='py-2'>{props.desc}</p>
            </div>
        </div>
    </div>
  )
}



