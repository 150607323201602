import React from "react";

const Registration = () => {
  return (
    <div>
      <div className="headerBg">
        <div className="bg-slate-100 bg-opacity-75 flex flex-col items-center ">
          <div className="relative bg-blue-100 rounded-2xl m-20 text-blue-800 font-sans">
            {/* div for side blue background */}
            <div className="absolute bg-blue-800 h-full right-0 rounded-r-2xl rounded-l-full lg:w-1/3"></div>
            <div className="lg:mr-96 p-5">
            <a href="/">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
            </svg>
            </a>
            <div className="text-center">
              <h1 className="text-3xl   font-bold">Register</h1>
              <p className="mb-5">Create an account</p>
            </div>
            <form className="w-full p-3 ">
              <div className="mb-5 ">
                <label htmlFor="name" className="block mb-2 text-sm font-medium">Full name</label>
                <input type="text" id="name" className="md:w-96 bg-blue-800 rounded-xl text-white placeholder:text-blue-100 " placeholder="Full name" required/>
              </div>
              <div className="mb-5 ">
                <label htmlFor="name" className="block mb-2 text-sm font-medium">Mobile number</label>
                <input type="text" id="number" className="md:w-96 bg-blue-800 rounded-xl text-white placeholder:text-blue-100" placeholder="+19 0000000000" required/>
              </div>
              <div className="mb-5 ">
                <label htmlFor="name" className="block mb-2 text-sm font-medium">Email</label>
                <input type="email" id="email" className="md:w-96 bg-blue-800 rounded-xl text-white placeholder:text-blue-100" placeholder="user@gmail.com" required/>
              </div>
              <div className="mb-5 ">
                <label htmlFor="name" className="block mb-2 text-sm font-medium"> Password</label>
                <input type="password" id="createPassword" className="md:w-96 bg-blue-800 rounded-xl text-white placeholder:text-blue-100" placeholder="Password" required/>
              </div>
              <div className="mb-5 ">
                <label htmlFor="name" className="block mb-2 text-sm font-medium">Confirm Password</label>
                <input type = "password"  id="confirmPassword" className="md:w-96 bg-blue-800 rounded-xl text-white placeholder:text-blue-100" placeholder="Re-enter Password" required/>
              </div>
              <div className="mb-5 ">
                <input type="submit" id="submitForm" className="md:w-96 p-4 w-full bg-blue-800 rounded-xl text-white placeholder:text-blue-100 cursor-pointer active:bg-blue-500" placeholder="Re-enter Password" required/>
              </div>
              <div className="mb-5 text-center ">
                <p>Already have an account? <strong>Login</strong></p>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
