import React from "react";
import TitleHead from "./TitleHead";
import BrandPartner from "../component/BrandPartner";
import about01 from '../image/about01.png'
import about02 from '../image/about02.png'
import LogisticServices from "../component/LogisticServices";
import BpsDelivery from "../component/BpsDelivery";
import ParcelDelivery from "../component/ParcelDelivery";
import FeedBack from "../component/FeedBack";
import QuestonsArea from "../component/QuestionArea";
import Contact from "../component/Contact";
import { Link } from "react-router-dom";
import PathRoute from "../router/PathRoute";

const AboutUs = () => {
  return (
    <>
      <TitleHead title="About Our Logistics" />
      <BrandPartner />
      <div className="bg-blue-200 bg-bgElement bg-no-repeat p-7 lg:px-20">
      <div className="grid grid-cols-1 lg:grid-cols-2  gap-5  sm:gap-10 md:p-5 my-8 sm:p-8">
        <div className="col-span-1rounded-2xl sm:p-9 ">
          <div className="sm:p-5 ">
          <dd x-for="user in project.users" className="flex justify-center -space-x-32 space-y-20 md:space-y-40">
              <img src={about01} alt="user.name" className=" rounded-2xl " loading="lazy"/>
              <img src={about02} alt="user.name" className=" sm:h-48 h-32 rounded-2xl bg-blue-100 border-8 border-blue-200" loading="lazy"/>
            </dd>
          </div>
        </div>
        <div className=" w-full col-span-1p-4">
        <div className="  ">
            <h1 className="text-4xl  font-bold font-san ">
            Our Company Overview            </h1>
            <p className="py-4 ">
            Pioneering solutions, driving success. <br /> Discover the essence of our company through excellence, trust, and innovation.
            </p>
          </div>
          <div className="grid sm:grid-cols-3 font-semibold w-full gap-4 py-4">
            <Link to={PathRoute.OurApproch} className="bg-white hover:bg-blue-800 hover:text-white shadow-md rounded-xl p-3 text-center">Our Approch</Link>
            <Link to={PathRoute.OurMission} className="bg-white hover:bg-blue-800 hover:text-white shadow-md rounded-xl p-3 text-center">Our Mission</Link>
            <Link to={PathRoute.OurVision} className="bg-white hover:bg-blue-800 hover:text-white shadow-md rounded-xl p-3 text-center">Our Vision</Link>


          </div>
          <div className="  ">
            <p className="py-4 ">
            Precision in delivery, excellence in service. <br /> Our approach focuses on tailored solutions, ensuring a seamless experience. <br /> Embrace reliability and efficiency with our dedicated team committed to your shipping needs.
            </p>
          </div>
          <div className="grid grid-cols-3 font-semibold w-full gap-4 py-4">
          <Link to={PathRoute.OurService} className="bg-blue-800 hover:bg-white hover:text-blue-800 text-white shadow-md rounded-xl p-3 text-center">Learn More</Link>
          </div>
        </div>
      </div>
      </div>
      <LogisticServices/>
      <BpsDelivery/>
      <ParcelDelivery/>
      <FeedBack/>
      <QuestonsArea/>
      <Contact/>
    </>
  );
};

export default AboutUs;
