const PathRoute ={

    AboutUs:"/aboutus",
    OurService: "/ourservice",
    ContactUs:"/contactus",
    Registration:"/registration",
    OurMission: "/ourmission",
    OurApproch: "/ourapproch",
    OurVision: "/ourvision",
}

export default PathRoute;