// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./image/Header.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.headerBg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});  
  background-size: cover;
  background-position: top ;
  background-repeat: no-repeat;
  width: 100%;
}

.card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2); /* Updated box-shadow with proper syntax and color */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,yDAA4C;EAC5C,sBAAsB;EACtB,yBAAyB;EACzB,4BAA4B;EAC5B,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,+CAA+C,EAAE,oDAAoD;AACvG","sourcesContent":["\n.headerBg {\n  background-image: url('./image/Header.jpeg');  \n  background-size: cover;\n  background-position: top ;\n  background-repeat: no-repeat;\n  width: 100%;\n}\n\n.card {\n  background-color: white;\n  border-radius: 16px;\n  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2); /* Updated box-shadow with proper syntax and color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
