import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosWithToken } from "./axios/AxiosObj";
import AppRoute from "./router/AppRoute";
import secureLocalStorage from "react-secure-storage";
import { logoutUser } from "./store/feature/userSlice";

function App() {
  const { isLogged } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const timer = useRef();
  const [flag, setFlag] = useState(false);

  const upDateToken = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: "token/update",
    });
    setFlag(true);
    if (response.data.success) {
      secureLocalStorage.setItem("access", response.data.token);
    } else {
      dispatch(logoutUser());
      window.location.reload();
    }
    console.log(response);
    console.log(isLogged);
  };
  const checkLoign = async () => {
    if (!secureLocalStorage.getItem("access")) {
      dispatch(logoutUser());
      window.location.reload();
    }
  };

  useEffect(() => {
    if (isLogged) {
      // Set up a single interval for both update and check
      timer.current = setInterval(async () => {
        if (!flag) {
          await upDateToken();
        }
        setFlag(false);
      }, 600000); // 10 min in milliseconds (6 * 1000)

      timer.current = setInterval(() => {
        checkLoign();
      }, 5000); // 5 sec in milliseconds (5 * 1000)
    }

    // Clear the interval when the component unmounts or when isLoggedIn becomes false
    return () => {
      clearInterval(timer.current);
    };
  }, [isLogged, flag]);
  

  return (
    <>
    <ToastContainer
        style={{ fontSize: "14px", zIndex: "10000" }}
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        limit={5}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    <AppRoute/>
    </>
  );
}

export default App;
